import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // 导入NProgress样式文件

Vue.use(VueRouter)

NProgress.configure({
    easing: 'ease-in-out',
    speed: 500,
    trickle: true,
    trickleSpeed: 200,
    showSpinner: false
});

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: 'login'
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: {requiresAuth: true},
        component: () => import('@/views/admin/Index.vue'),
        redirect: '/admin/userManage',
        children: [
            {
                path: 'userManage',
                name: 'UserManage',
                component: () => import('@/components/admin/UserManage.vue')
            },
        ]
    },
    {
        name: 'Login',
        path: '/login',
        component: () => import('@/views/login/Index.vue')
    },
    {
        path: '/reg',
        name: 'Register',
        component: () => import('@/views/login/register.vue')
    },
    {
        path: '/qrlogin',
        name: 'Qrlogin',
        component: () => import('@/views/qrLogin/Index.vue')
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('@/views/user/Index.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path: '',
                name: 'Dashboard',
                redirect: 'dashboard' // 默认重定向到dashboard
            },
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('@/views/user/dashboard.vue')
            },
            {
                path: 'setting',
                name: 'Setting',
                component: () => import('@/views/user/settings.vue'),
                props: route => ({
                    tab: route.query.tab,
                }) // 将路由参数传递给组件的props
            },
            {
                name: 'CardManage',
                path: 'cardManage',
                component: () => import('@/views/user/cardManage.vue')
            },
            {
                path: 'recharge',
                name: 'Recharge',
                component: () => import('@/views/user/recharge.vue')
            },
            {
                path: 'accountList',
                name: 'AccountList',
                component: () => import('@/views/user/accountList.vue')
            },
            {
                path: 'personal',
                name: 'Personal',
                component: () => import('@/views/user/personal.vue')
            },
            {
                path: 'scanLog',
                name: 'ScanLog',
                component: () => import('@/views/user/ScanLog.vue')
            },
            {
                path: 'pageTemplate',
                name: 'PageTemplate',
                component: () => import('@/views/user/PageTemplate.vue')
            },
            {
                path: 'introduction',
                name: 'Introduction',
                component: () => import('@/views/user/introduction.vue')
            },
        ]
    },
    // 其他路由
    {
        path: '/:pathMatch(.*)*',  // 匹配所有路径
        component: () => import('@/components/404/Index.vue')
    }
]

const router = new VueRouter({
    routes
})


// 获取token
function isAuthenticated() {
    return localStorage.getItem('token') !== null;
}

// 导航守卫
router.beforeEach((to, from, next) => {
    NProgress.start();

    // 如果访问的是 /login 路由，且 token 存在，重定向到 /user
    if (to.path === '/login' && isAuthenticated()) {
        let account = localStorage.getItem("account")
        if (account && account.includes("admin")){
            next({path: '/admin'});
        }else {
            next({path: '/user'});
        }
    } else {
        next(); // 继续导航
    }


    // 检查是否需要登录才能访问的路由
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated()) {
            NProgress.done();
            next('/login');
        } else {
            next();
        }
    } else {
        // 如果路由不需要登录，直接允许访问
        next();
    }
});

router.afterEach(() => {
    // 结束进度条
    NProgress.done();
});


export default router
