import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/style/element-variables.scss'
import 'normalize.css/normalize.css';
import App from './App.vue'
import router from './router'
import request from '@/api/api'
import permission from '@/utils/permission.js';

Vue.prototype.$WEB_NAME = '魔方扫码';
Vue.prototype.$IS_ME = window.location.hostname === 'qr.nbsl.xyz';
Vue.prototype.$IS_ME = true
Vue.prototype.$SUB_SYSTEM = [
    {label: '百度网盘', value: 'wp', desc: '子系统'},
    {label: '百度文库', value: 'wk', desc: '子系统'},
    {label: '迅雷系统', value: 'xl', desc: '子系统'},
    {label: 'wps系统', value: 'wps', desc: '子系统'},
    {label: '夸克系统', value: 'quark', desc: '子系统'},
];

Vue.config.productionTip = false
Vue.use(ElementUI, {size: 'medium', zIndex: 3000});
Vue.directive('permission', permission);
Vue.prototype.$request = request

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

